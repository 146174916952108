<template>
  <div>
    <s-dialog :open="true" size="sm" to="stds-dialog-confirm-delete-build">
      <s-dialog-overlay />
      <s-dialog-panel>
        <s-dialog-content>
          <s-dialog-content-body>
            <p class="text-xl font-bold leading-lg text-on-surface-elevation-1">
              {{
                $t(
                  'studio.prj_prod.this_prod.product_data.build.home_upload_status_paused_pop1_delete_build_btn'
                )
              }}
            </p>
            <safe-html
              tag="p"
              class="mt-4 text-xs leading-xs font-regular text-on-surface-elevation-4"
              :html="$t('studio.prj_prod_mngmt.prod_home.prod_terms.term_del_guide1')"
            />
            <div class="mt-8">
              <input-text
                ref="deleteInput"
                v-model="deleteForm"
                name="deleteForm"
                :placeholder="CONFIRM_TYPE.DELETE"
                maxLength="6"
                :pattern="REGEX_DELETE"
                :countable="false"
                :clearable="false"
                :rules="{
                  required: { value: true, showError: false }
                }"
                inputClass="uppercase"
              />
            </div>
          </s-dialog-content-body>
        </s-dialog-content>
        <s-dialog-footer>
          <s-button class="w-full" variant="outline" @click="onCancel">
            {{ $t('studio.common.popup_case_cancel_btn') }}
          </s-button>
          <s-button
            class="w-full"
            variant="red"
            :disabled="!isDeleteEnabled || !meta.valid"
            @click="onDelete"
          >
            {{ $t('studio.prj_prod.this_prod.product_data.build_del_pop_del_cf_btn') }}
          </s-button>
        </s-dialog-footer>
      </s-dialog-panel>
    </s-dialog>
    <s-portal-target name="stds-dialog-confirm-delete-build" />
  </div>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { deleteProductBuildApi } from '@/apis/build.api';
import SafeHtml from '@/components/common/safe-html.vue';
import InputText from '@/components/validation/input-text.vue';
import { showAlert } from '@/composables/useDialog';
import { CONFIRM_TYPE } from '@/constants/common.const';
import { COMMON_ERROR_MESSAGE_KEY } from '@/constants/error.const';
import { PRODUCT_BUILD_PAGE_URL } from '@/constants/url.const';
import type { Build } from '@/types/build/build.type';
import { redirectTo } from '@/utils/common.util';

const props = defineProps<{
  build: Build;
}>();

// Route
const route = useRoute();

const { handleSubmit, meta } = useForm({
  initialValues: {
    deleteForm: ''
  }
});

const { t } = useI18n();

const REGEX_DELETE = /^.{0,6}$/;

const emits = defineEmits<{
  close: [v: boolean];
}>();

const isDeleteEnabled = ref(false);
const deleteForm = ref('');

watch(
  () => deleteForm.value,
  (newValue: string) => {
    isDeleteEnabled.value = newValue.toUpperCase() === CONFIRM_TYPE.DELETE;
  }
);

const onCancel = () => {
  emits('close', false);
};

const onDelete = handleSubmit(async () => {
  const response: any | undefined = await deleteProductBuildApi(
    props.build.buildId,
    props.build.gameId
  );
  if (!response) {
    return;
  }

  if (response.success) {
    await showAlert({
      severity: 'info',
      content: t('studio.common.popup_case_f_complete_del'),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });

    emits('close', true);
    handleAfterDelete();
  } else {
    await showAlert({
      severity: 'info',
      content: t(COMMON_ERROR_MESSAGE_KEY),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });
  }
});

const handleAfterDelete = () => {
  const currentPath = route.path;
  const isBuildDetailPage = currentPath.includes(`/build/${props.build.buildId}`);

  if (isBuildDetailPage) {
    redirectTo(PRODUCT_BUILD_PAGE_URL);
  } else {
    window.location.reload();
  }
};
</script>
