import { ref, shallowRef, watch } from 'vue';

import DownloadUploaderDialog from '@/components/build/dialog/download-uploader-dialog.vue';
import { hasActiveDialog, showDialog } from '@/composables/useDialog';
import useUriHandler from '@/composables/useUriHandler';

const isUploaderActive = ref(false);

/**
 * Sets up and manages the DownloadUploaderDialog
 *
 * @returns {Object} Object containing methods and refs to manage the dialog
 */
export const useDownloadUploaderDialog = () => {
  const { isError } = useUriHandler();
  const showUploaderDialog = ref(false);

  const isAttemptingUriOpen = ref(false);

  const hadRecentError = ref(false);

  // Watch for URI errors and show dialog if needed
  watch(isError, async (value: boolean) => {
    if (value && !hasActiveDialog.value && isUploaderActive.value) {
      if (showUploaderDialog.value !== undefined) {
        showUploaderDialog.value = true;
      } else {
        // If using the composable method
        await showDialog({
          component: shallowRef(DownloadUploaderDialog),
          props: {
            class: 'max-w-300'
          },
          severity: 'info'
        });
      }
    }

    // When we detect an error occurred, set the flags to prevent auto-closing
    if (value) {
      isAttemptingUriOpen.value = false;
      hadRecentError.value = true;

      setTimeout(() => {
        hadRecentError.value = false;
      }, 1000); // 1 second
    } else {
      hadRecentError.value = false;
    }
  });

  watch(() => isAttemptingUriOpen.value, (attempting: boolean) => {
    // Set a timeout to reset the flag in case the isError watcher doesn't trigger
    if (attempting) {
      setTimeout(() => {
        isAttemptingUriOpen.value = false;
      }, 2000); // 2 seconds should be enough
    }
  });

  // Setup visibility listeners to close dialog when document is hidden
  const setupVisibilityListeners = () => {
    const closeDialogOnHidden = () => {
      // Logic to determine if we should close the dialog:
      // 1. Not in the middle of a URI open attempt
      // 2. Either there's no error OR enough time has passed since the last error
      const shouldClose = !isAttemptingUriOpen.value && !hadRecentError.value;

      if (shouldClose) {
        showUploaderDialog.value = false;
      }
    };

    const visibilityChangeHandler = () => {
      if (document.visibilityState === 'hidden') {
        closeDialogOnHidden();
      }
    };

    const blurHandler = () => {
      closeDialogOnHidden();
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    window.addEventListener('blur', blurHandler);

    // Return cleanup function
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      window.removeEventListener('blur', blurHandler);
    };
  };

  // Close the dialog
  const closeUploaderDialog = () => {
    showUploaderDialog.value = false;
  };

  const beforeUriOpen = () => {
    isAttemptingUriOpen.value = true;
    hadRecentError.value = false; // Reset any previous error state
    isUploaderActive.value = true;

    setTimeout(() => {
      isUploaderActive.value = false;
    }, 5000);
  };

  return {
    showUploaderDialog,
    setupVisibilityListeners,
    closeUploaderDialog,
    beforeUriOpen,
    isAttemptingUriOpen
  };
};
