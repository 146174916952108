<template>
  <!-- D : DRM Maker 파일 선택 -->
  <s-dialog to="stds-dialog-overview" size="md" :open="isOpen">
    <s-dialog-overlay />
    <s-dialog-panel class="relative !w-[56rem] rounded-[2.8rem]">
      <st-dialog-header class="pt-20 px-32" @clickClose="handleClose" />
      <s-dialog-content class="px-32 pb-24 m-0">
        <s-dialog-content-body class="flex flex-col gap-20 items-center !py-0">
          <div ref="uploadAnimationRef" class="w-[14rem] h-80"></div>
          <div class="w-full flex flex-col gap-8 items-center justify-center mt-8">
            <safe-html
              tag="h3"
              class="!font-bold text-on-surface-elevation-1 text-3xl leading-2xl"
              :html="$t('studio.build_mgmt.uploader_running_alert')"
            />
            <div class="flex flex-col items-center">
              <safe-html
                tag="p"
                class="text-on-surface-elevation-2 text-center text-lg leading-lg py-[1.1rem]"
                :html="$t('studio.build_mgmt.uploader_running_alert_msg1')"
              />
              <safe-html
                tag="p"
                class="text-on-surface-elevation-3 text-sm leading-md"
                :html="$t('studio.build_mgmt.uploader_running_alert_msg2')"
              />
            </div>
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer class="!pt-8">
        <s-button class="!w-264" @click="downloadUploader">
          {{ $t('studio.build_mgmt.uploader_download_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-overview" />
</template>

<script lang="ts" setup>
import lottie from 'lottie-web';
import { onMounted, onUnmounted, ref, shallowRef } from 'vue';

import DownloadUploaderDialog from '@/components/build/dialog/download-uploader-dialog.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import { closeDialog } from '@/composables/useDialog';
import { getConfigs } from '@/utils/common.util';

const isOpen = ref(true);
const uploadAnimationRef = ref<HTMLElement | null>(null);
const uploadAnimation = ref<any>(null);
let animation: any = null;

const emit = defineEmits(['close']);

const handleClose = () => {
  isOpen.value = false;
  emit('close');
  closeDialog(
    {
      component: shallowRef(DownloadUploaderDialog),
      props: {},
      severity: 'info'
    },
    null
  );
};

const { UPLOADER_DOWNLOAD_URL } = getConfigs();

const downloadUploader = () => {
  window.location.assign(`${UPLOADER_DOWNLOAD_URL}?timestamp=${new Date().getTime()}`);
};

onMounted(async () => {
  // Animation Loading
  try {
    uploadAnimation.value = await import('@/assets/images/build/build_upload.json');

    if (uploadAnimationRef.value && uploadAnimation.value) {
      animation = lottie.loadAnimation({
        container: uploadAnimationRef.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: uploadAnimation.value.default
      });
    }
  } catch (error) {
    // console.error('Failed to load animation:', error);
  }
});
onUnmounted(() => {
  if (animation) {
    animation.destroy();
  }
});
</script>
