import { PLATFORM } from '@/constants/common.const';

export const LAUNCH_SETTING_GUIDE_PART = {
  INSTALLATION_FOLDER: 'installationFolder',
  PLATFORM: 'platform',
  PC_CLIENT_SETTING: 'pcClientSetting'
} as const;

export const LAUNCH_SETTING_REQUIRED_PROGRAMS = {
  VCREDIST: 'vcredist',
  DOTNET: 'dotnet',
  DIRECTX: 'directx',
  OPENAL: 'openal',
  GAME_ENGINE: 'game_engine',
  VR: 'vr'
};

export const REQUIRED_PROGRAM_DISPLAY_AS = {
  DROP_BOX: 'dropbox',
  TEXT_INPUT: 'text-input'
};

export const PLATFORMS_DISPLAY = [
  {
    label: 'PC',
    value: PLATFORM.PC
  },
  {
    label: 'Web',
    value: PLATFORM.WEB
  }
  // {
  //   label: 'Mobile',
  //   value: PLATFORM.MOBILE
  // }
];

export const MAX_LENGTH_INSTALLATION_FOLDER_NAME = '60';
