import Bowser from 'bowser';
import { useNuxtApp } from 'nuxt/app';
import { COOKIE_KEYS } from 'seed-core';

import { callAppLauncherApi } from '@/apis/build.api';
import ConfirmDeleteBuild from '@/components/build/dialog/confirm-delete-build.vue';
import { useCookieWithDomain } from '@/composables/useCookieWithDomain';
import { showAlert, showDialog } from '@/composables/useDialog';
import { useDownloadUploaderDialog } from '@/composables/useDownloadUploaderDialog';
import useUriHandler from '@/composables/useUriHandler';
import {
  BUILD_PROGRESS_MESSAGES,
  ERROR_CODE_NEEDING_EMAIL_SUPPORT,
  ERROR_TYPE,
  type ErrorType,
  type LocaleType,
  type LocalizedImageType,
  RELEASE_STATUS_BUILD,
  SECURITY_PROGRESS_MESSAGES,
  UPLOAD_STATUS_MESSAGE,
  VARIABLE_CODE_LANG,
  VARIABLE_CODE_LANG_BUILD
} from '@/constants/build.const';
import { DEFAULT_NATION } from '@/constants/common.const';
import { DEFAULT_LOCALE } from '@/constants/locale.const';
import { STOVE_LINK } from '@/enums/common.enum';
import { RatingRevisionType, RevisionType } from '@/enums/rating.enum';
import type {
  Build,
  ErrorMessageOptions,
  UploadBuildParams,
  UploadStatusMessage
} from '@/types/build/build.type';

const getUploadStatus = (build: Build): UploadStatusMessage => {
  switch (build.uploadStatus) {
    case UPLOAD_STATUS_MESSAGE.IN_PROGRESS:
      return UPLOAD_STATUS_MESSAGE.IN_PROGRESS;
    case UPLOAD_STATUS_MESSAGE.STOPPED:
      return UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED;
    case UPLOAD_STATUS_MESSAGE.FAIL:
      return UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED;
    case UPLOAD_STATUS_MESSAGE.SUCCESS:
      return handleSuccessStatus(build);
    default:
      return UPLOAD_STATUS_MESSAGE.FAILURE;
  }
};

function handleSuccessStatus(build: Build): UploadStatusMessage {
  const { build: buildStatus, vaccine, reviewStatus } = build;

  if (
    ((buildStatus?.status === UPLOAD_STATUS_MESSAGE.SUCCESS ||
      vaccine?.status === UPLOAD_STATUS_MESSAGE.SUCCESS) &&
      ((buildStatus?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
        buildStatus?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS) ||
        (vaccine?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
          vaccine?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS))) ||
    (buildStatus?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS &&
      buildStatus?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
      vaccine?.status !== UPLOAD_STATUS_MESSAGE.SUCCESS &&
      vaccine?.status !== UPLOAD_STATUS_MESSAGE.FAIL)
  ) {
    return UPLOAD_STATUS_MESSAGE.REVIEWING;
  }

  if (
    buildStatus?.status === UPLOAD_STATUS_MESSAGE.FAIL &&
    vaccine?.status !== UPLOAD_STATUS_MESSAGE.FAIL
  ) {
    return UPLOAD_STATUS_MESSAGE.BUILD_CREATION_FAILED;
  }

  if (
    buildStatus?.status !== UPLOAD_STATUS_MESSAGE.FAIL &&
    vaccine?.status === UPLOAD_STATUS_MESSAGE.FAIL
  ) {
    if (reviewStatus === null) {
      return UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_FAILED;
    }
    if (reviewStatus === UPLOAD_STATUS_MESSAGE.REQUEST) {
      return UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_REVIEWING;
    }
  }

  if (
    buildStatus?.status === UPLOAD_STATUS_MESSAGE.FAIL &&
    vaccine?.status === UPLOAD_STATUS_MESSAGE.FAIL
  ) {
    return UPLOAD_STATUS_MESSAGE.FAILURE;
  }

  if (
    buildStatus?.status === UPLOAD_STATUS_MESSAGE.SUCCESS &&
    vaccine?.status === UPLOAD_STATUS_MESSAGE.SUCCESS
  ) {
    return UPLOAD_STATUS_MESSAGE.SUCCESS;
  }

  if (
    buildStatus?.status === UPLOAD_STATUS_MESSAGE.SUCCESS &&
    vaccine?.status === UPLOAD_STATUS_MESSAGE.FAIL &&
    reviewStatus === UPLOAD_STATUS_MESSAGE.APPROVED
  ) {
    return UPLOAD_STATUS_MESSAGE.SUCCESS;
  }

  return UPLOAD_STATUS_MESSAGE.FAILURE;
}

const allowedStatusForDelete: (UploadStatusMessage | string)[] = [
  UPLOAD_STATUS_MESSAGE.UPLOAD_STOPPED,
  UPLOAD_STATUS_MESSAGE.UPLOAD_FAILED,
  UPLOAD_STATUS_MESSAGE.BUILD_CREATION_FAILED,
  UPLOAD_STATUS_MESSAGE.SECURITY_INSPECTION_FAILED,
  UPLOAD_STATUS_MESSAGE.FAILURE
];

const isDeleteButtonDisabled = (build: Build) => {
  const uploadStatus = getUploadStatus(build);

  if (uploadStatus === UPLOAD_STATUS_MESSAGE.SUCCESS) {
    return !(
      build.releaseStatus === RELEASE_STATUS_BUILD.VERIFY_REVIEW_REJECTED ||
      build.releaseStatus === RELEASE_STATUS_BUILD.NONE
    );
  }

  return !allowedStatusForDelete.includes(uploadStatus);
};

const handleDelete = async (build: Build, canEdit: boolean = true) => {
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n as { t: any };

  if (!canEdit) {
    await showAlert({
      severity: 'info',
      content: t('studio.common.popup_case_h_not_edit'),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });
  } else if (isDeleteButtonDisabled(build)) {
    await showAlert({
      severity: 'info',
      content: t('studio.prj_prod.this_prod.product_data.build.home_list_delete_n_alert_msg'),
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      confirmClasses: '!max-w-full !w-full'
    });
  } else {
    await showDialog({
      component: ConfirmDeleteBuild,
      props: {
        class: 'max-w-[31.2rem]',
        build
      },
      severity: 'info'
    });
  }
};

/**
 * Converts a given number of bytes into GB.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @returns {number} The formatted size in GB.
 *
 * @example
 * const fileSize = 123456789;
 * const readableSize = convertBytesToGB(fileSize); // Returns 117.738
 */
const convertBytesToGB = (bytes?: number): number => {
  const k = 1024;
  const dm = 3;

  if (!bytes || bytes === 0) {
    return 0;
  }

  return Number.parseFloat((bytes / (k * k * k)).toFixed(dm));
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=487021232
const handleUploadBuild = async ({
  selectedGroupId,
  accessToken,
  gameId,
  langCd = DEFAULT_LOCALE,
  buildId,
  nationCd = DEFAULT_NATION
}: UploadBuildParams) => {
  if (!accessToken) {
    return;
  }

  if (!(await checkWindowsAndShowAlert())) {
    return;
  }

  const buildInfo = await callAppLauncherApi(selectedGroupId);
  if (!buildInfo) {
    showAlert({
      content: 'Failed to fetch build info.'
    });
    return;
  }

  const refreshToken = useCookieWithDomain(COOKIE_KEYS.REFRESH_TOKEN || '');
  const queryParams = new URLSearchParams();
  queryParams.append('lang_cd', langCd);
  queryParams.append('nation_cd', nationCd);
  queryParams.append('refresh_token', refreshToken.value || '');
  if (buildId) {
    queryParams.append('build_id', buildId);
  }

  // stoveuploader{env}://{access_key}/{access_token}/{game_id}[?lang_cd={value1}&nation_cd={value2}&build_id={value3}&refresh_token={value4}]
  const baseUri = `${buildInfo?.protocol}://${buildInfo?.accessKey}/${accessToken}/${gameId}`;
  const uri = `${baseUri}?${queryParams.toString()}`;

  const { openUri } = useUriHandler();
  const { beforeUriOpen, showUploaderDialog } = useDownloadUploaderDialog();

  // Always show the download uploader dialog first
  beforeUriOpen();
  showUploaderDialog.value = true;

  // Create a flag to track if the app has launched
  let isAppLaunched = false;

  // Track window focus/blur
  const handleAppLaunch = () => {
    // Set a timer that will check if focus was gained back quickly (system popup)
    // or if focus stays away (actual app launch)
    const focusCheckTimeout = setTimeout(() => {
      if (!document.hasFocus()) {
        // If focus is still gone after the delay, app probably launched
        isAppLaunched = true;

        // Wait a bit longer to ensure app is properly started
        setTimeout(() => {
          if (isAppLaunched) {
            // Auto-close the dialog only if app launched
            showUploaderDialog.value = false;
          }
        }, 2000);
      }
    }, 3000);

    // If window gets focus back quickly, clear the auto-close timers
    const quickFocusHandler = () => {
      clearTimeout(focusCheckTimeout);
      window.removeEventListener('focus', quickFocusHandler);
    };

    window.addEventListener('focus', quickFocusHandler);
  };

  // Listen for blur indicating app may have launched
  window.addEventListener('blur', handleAppLaunch, { once: true });

  // Clean up after reasonable timeout
  setTimeout(() => {
    window.removeEventListener('blur', handleAppLaunch);
  }, 15000);

  // Attempt to open the URI with shorter timeout for faster response
  openUri(uri, 0);
};

const isWindowsOS = (): boolean => {
  // eslint-disable-next-line import/no-named-as-default-member
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getOSName(true) === 'windows';
};

const checkWindowsAndShowAlert = async (): Promise<boolean> => {
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n as { t: any };

  if (!isWindowsOS()) {
    await showAlert({
      content: t('studio.prj_prod.this_prod.product_data.build.home_upload_alert_msg2'),
      confirmLabel: t('studio.common.popup_case_cf_btn')
    });
    return false;
  }

  return true;
};

const handleDownloadLauncher = async (uri: string) => {
  if (!(await checkWindowsAndShowAlert())) {
    return;
  }

  const { openUri, isError } = useUriHandler();

  isError.value = false;

  openUri(uri, 0);
};

const releaseStatusBuild = (value: string | null) => {
  if (!value) {
    return '-';
  }
  const nuxtApp = useNuxtApp();
  const { t } = nuxtApp.$i18n as { t: any };

  switch (value) {
    case RELEASE_STATUS_BUILD.NONE:
      return '-';
    case RELEASE_STATUS_BUILD.VERIFY_UNDER_REVIEW:
      return t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_stt_2');
    case RELEASE_STATUS_BUILD.VERIFY_REVIEW_REJECTED:
      return t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_stt_3_rejected');
    case RELEASE_STATUS_BUILD.VERIFY_REVIEW_COMPLETED:
      return t('studio.prj_prod_mngmt.rating_build_review.self_review.under_review_stt_4');
    case RELEASE_STATUS_BUILD.RELEASE_UNDER_REVIEW:
      return t('studio.prj_prod.status_in_review');
    case RELEASE_STATUS_BUILD.COMING_SOON:
      return t('studio.prj_prod.this_prod.home_apply_stt1');
    case RELEASE_STATUS_BUILD.APPLY_LIVE:
      return t('studio.prj_prod.this_prod.home_apply_stt2');
    case RELEASE_STATUS_BUILD.END:
      return t('studio.prj_prod.this_prod.home_prod_stt_terminated');
    default:
      return '-';
  }
};

/**
 * How to use get message error
 *
 * @getErrorMessage(errorCode);
 * @getErrorMessage(errorCode, { context: 'build' });
 * @getErrorMessage(errorCode, { context: 'general' });
 * @getErrorMessage(errorCode, { context: 'build', defaultValue: 'Default message' });
 * @getErrorMessage(errorCode, 'Default message');
 *
 * @example
 * const errorCode = 123456789;
 * const message = getErrorMessage(errorCode);
 * // console.log(message); // Output: 'Default message'
 */
const getErrorMessage = (
  errorCode: number | null | undefined,
  options?: ErrorMessageOptions | string
): string => {
  if (!errorCode) {
    return '';
  }

  const config: ErrorMessageOptions =
    typeof options === 'string' ? { defaultValue: options } : options || {};

  const context: ErrorType = config.context || ERROR_TYPE.GENERAL;
  const defaultValue = config.defaultValue || '';

  if (context === ERROR_TYPE.BUILD) {
    const buildMessage = VARIABLE_CODE_LANG_BUILD[errorCode];
    if (buildMessage) {
      return buildMessage;
    }
  }

  return VARIABLE_CODE_LANG[errorCode] || defaultValue;
};

const renderValueMessageBuild = (step: number | undefined | null) => {
  if (step === null || step === undefined) {
    return {
      message: '',
      displayStep: 0,
      className: '',
      totalSteps: 0
    };
  }

  const getDisplayStep = (step: number): number => {
    if (step <= 0) {
      return step;
    }
    if (step >= 1 && step <= 3) {
      return 1;
    }
    if (step === 4) {
      return 2;
    }
    if (step >= 5 && step <= 8) {
      return 3;
    }
    if (step === 9 || step === 10) {
      return 4;
    }
    if (step === 11 || step === 12) {
      return 5;
    }
    return 5;
  };

  return {
    message: BUILD_PROGRESS_MESSAGES[step] || '',
    displayStep: getDisplayStep(step),
    className: step === 0 ? 'text-system-red' : '',
    totalSteps: 5
  };
};

const renderValueMessageSecurity = (step: number | undefined | null) => {
  // Because step can be === 0
  if (step === null || step === undefined) {
    return {
      message: '',
      displayStep: 0,
      className: '',
      totalSteps: 0
    };
  }

  return {
    message: SECURITY_PROGRESS_MESSAGES[step] || '',
    displayStep: step === 3 || step === 4 ? 3 : step,
    className: step === 0 ? 'text-system-red' : '',
    totalSteps: 3
  };
};

const getErrorMessageVariables = (errorCode?: number | null | undefined) => {
  const codesNeedingEmailSupport = ERROR_CODE_NEEDING_EMAIL_SUPPORT;

  if (errorCode && codesNeedingEmailSupport.includes(errorCode.toString())) {
    return {
      discordSupport: STOVE_LINK.DISCORD_INVITE
    };
  }

  return {};
};

const getLocalizedImagePath = (
  images: LocalizedImageType,
  locale: LocaleType,
  fallback: LocaleType = DEFAULT_LOCALE
): string => {
  return images[locale] || images[fallback];
};

const mapRevisionTypeToApiValue = (revisionType: string): string => {
  switch (revisionType) {
    case RevisionType.BugFix:
      return RatingRevisionType.BUG_FIX;
    case RevisionType.Etc:
      return RatingRevisionType.OTHERS;
    case RevisionType.Background:
      return RatingRevisionType.CHANGED_CONTENTS_3;
    case RevisionType.Ui:
      return RatingRevisionType.CHANGED_CONTENTS_4;
    case RevisionType.Billing:
      return RatingRevisionType.MODIFIED_BILLING_SYSTEM;
    case RevisionType.Scenario:
      return RatingRevisionType.CHANGED_CONTENTS_2;
    case RevisionType.Character:
      return RatingRevisionType.CHANGED_CONTENTS_1;
    default:
      return RatingRevisionType.OTHERS;
  }
};

const mapApiValueToRevisionType = (apiValue: string): string => {
  switch (apiValue) {
    case RatingRevisionType.OTHERS:
      return RevisionType.Etc;
    case RatingRevisionType.CHANGED_CONTENTS_3:
      return RevisionType.Background;
    case RatingRevisionType.CHANGED_CONTENTS_4:
      return RevisionType.Ui;
    case RatingRevisionType.MODIFIED_BILLING_SYSTEM:
      return RevisionType.Billing;
    case RatingRevisionType.CHANGED_CONTENTS_2:
      return RevisionType.Scenario;
    case RatingRevisionType.CHANGED_CONTENTS_1:
      return RevisionType.Character;
    default:
      return RevisionType.Etc;
  }
};

const isPermissionDelete = (buildMemberNo: number, memberNo?: number): boolean => {
  return memberNo !== undefined && buildMemberNo === memberNo;
};

export {
  convertBytesToGB,
  getErrorMessage,
  getErrorMessageVariables,
  getLocalizedImagePath,
  getUploadStatus,
  handleDelete,
  handleDownloadLauncher,
  handleUploadBuild,
  isDeleteButtonDisabled,
  isPermissionDelete,
  mapApiValueToRevisionType,
  mapRevisionTypeToApiValue,
  releaseStatusBuild,
  renderValueMessageBuild,
  renderValueMessageSecurity
};
