import Bowser from 'bowser';
import { ref } from 'vue';

import { BROWSER_NAME } from '@/constants/common.const';

const isError = ref<boolean>(true);

const useUriHandler = () => {
  const openUri = (uri: string, executeTimeout: number = 4000) => {
    isError.value = false;

    if ((window.navigator as any).msLaunchUri) {
      openUriWithMsLaunchUri(uri);
    } else {
      // eslint-disable-next-line import/no-named-as-default-member
      const browser: Bowser.Parser.Parser = Bowser.getParser(window.navigator.userAgent);
      const browserName: string = browser.getBrowserName();

      switch (browserName) {
        case BROWSER_NAME.OPERA:
        case BROWSER_NAME.FIREFOX:
          openUriUsingFirefox(uri, executeTimeout);
          break;
        case BROWSER_NAME.CHROME:
        case BROWSER_NAME.EDGE:
        case BROWSER_NAME.NAVER:
          openUriWithTimeoutHack(uri, executeTimeout);
          break;
        case BROWSER_NAME.IE:
          openUriUsingIEInOlderWindows(uri, executeTimeout);
          break;
        default:
          // Other unidentified browsers are considered and handled as chromium.
          openUriWithTimeoutHack(uri, executeTimeout);
      }
    }
  };

  const openUriUsingFirefox = (uri: string, executeTimeout: number) => {
    const iframe = createHiddenFrame();
    let hasOpened = false;
    let interval: ReturnType<typeof setTimeout> | undefined;

    const onBlur = (): void => {
      if (interval) {
        clearTimeout(interval);
      }
      hasOpened = true;
      isError.value = false;
      window.removeEventListener('blur', onBlur);
    };

    window.addEventListener('blur', onBlur);

    try {
      if (!iframe.contentWindow) {
        isError.value = true;
        window.removeEventListener('blur', onBlur);
        return;
      }
      iframe.contentWindow.location.href = uri;

      interval = setTimeout(() => {
        if (!hasOpened) {
          isError.value = true;
          window.removeEventListener('blur', onBlur);
        }
      }, executeTimeout);
    } catch (error: any) {
      if (error.name === 'NS_ERROR_UNKNOWN_PROTOCOL') {
        isError.value = true;
      }
      if (interval) {
        clearTimeout(interval);
      }
      window.removeEventListener('blur', onBlur);
    }
  };

  const createHiddenFrame = () => {
    let iframe: HTMLIFrameElement | null = document.querySelector('#hiddenIframe');

    if (!iframe) {
      iframe = createHiddenIframe(document.body);
    }

    return iframe;
  };

  const createHiddenIframe = (target: HTMLElement) => {
    const iframe: HTMLIFrameElement = document.createElement('iframe');

    iframe.src = 'about:blank';
    iframe.id = 'hiddenIframe';
    iframe.style.display = 'none';

    target.appendChild(iframe);

    return iframe;
  };

  const openUriWithTimeoutHack = (uri: string, executeTimeout: number) => {
    let target: Window = window;
    let hasOpened = false;
    let interval: ReturnType<typeof setTimeout> | undefined;

    const onBlur = (): void => {
      if (interval) {
        clearTimeout(interval);
      }
      target.removeEventListener('blur', onBlur);
      hasOpened = true;
      isError.value = false;
    };

    try {
      window.location.href = uri;

      interval = setTimeout((): void => {
        if (!hasOpened) {
          target.removeEventListener('blur', onBlur);
          isError.value = true;
        }
      }, executeTimeout);

      while (target !== target.parent) {
        target = target.parent;
      }

      target.addEventListener('blur', onBlur);
    } catch (error) {
      isError.value = true;
      if (interval) {
        clearTimeout(interval);
      }
      target.removeEventListener('blur', onBlur);
    }
  };

  const openUriUsingIEInOlderWindows = (uri: string, executeTimeout: number) => {
    // eslint-disable-next-line import/no-named-as-default-member
    const browser: Bowser.Parser.Parser = Bowser.getParser(window.navigator.userAgent);
    const BrowserVersion: number = Number(browser.getBrowserVersion());

    if (BrowserVersion === 10) {
      openUriUsingIE10InWindows7(uri, executeTimeout);
    } else if (BrowserVersion === 9 || BrowserVersion === 11) {
      openUriWithHiddenFrame(uri, executeTimeout);
    } else {
      openUriInNewWindowHack(uri, executeTimeout);
    }
  };

  const openUriUsingIE10InWindows7 = (uri: string, executeTimeout: number) => {
    let hasOpened = false;
    let interval: ReturnType<typeof setTimeout> | undefined;

    const onBlur = (): void => {
      if (interval) {
        clearTimeout(interval);
      }
      isError.value = false;
      hasOpened = true;
    };

    window.addEventListener('blur', onBlur);

    const iframe = createHiddenFrame();
    if (!iframe.contentWindow) {
      return;
    }

    try {
      iframe.contentWindow.location.href = uri;

      interval = setTimeout(() => {
        if (!hasOpened) {
          isError.value = true;
        }
      }, executeTimeout);
    } catch (error) {
      isError.value = true;
      if (interval) {
        clearTimeout(interval);
      }
    }
  };

  const openUriWithHiddenFrame = (uri: string, executeTimeout: number) => {
    const target: Window = window;
    let hasOpened = false;

    const onBlur = (): void => {
      if (interval) {
        clearTimeout(interval);
      }
      target.removeEventListener('blur', onBlur);
      isError.value = false;
      hasOpened = true;
    };

    target.addEventListener('blur', onBlur);

    const iframe = createHiddenFrame();
    if (!iframe.contentWindow) {
      return;
    }

    iframe.contentWindow.location.href = uri;

    const interval = setTimeout((): void => {
      if (!hasOpened) {
        target.removeEventListener('blur', onBlur);
        isError.value = true;
      }
    }, executeTimeout);
  };

  const openUriInNewWindowHack = (uri: string, executeTimeout: number) => {
    const myWindow: Window | null = window.open('', '', 'width=0,height=0');

    if (!myWindow) {
      return;
    }

    myWindow.document.write(`<iframe src='${uri}'></iframe>`);

    setTimeout((): void => {
      try {
        myWindow.setTimeout('window.close()', executeTimeout);

        isError.value = false;
      } catch (error) {
        myWindow.close();

        isError.value = true;
      }
    }, executeTimeout);
  };

  const openUriWithMsLaunchUri = (uri: string) => {
    (window.navigator as any).msLaunchUri(
      uri,
      () => {
        isError.value = false;
      },
      (_error: any) => {
        // console.error(error);
        isError.value = true;
      }
    );
  };

  return {
    openUri,
    isError
  };
};

export default useUriHandler;
